import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import DownloadIcon from '@mui/icons-material/Download';
import { useMoodleV2 } from "../../../contexts/moodle";

export const ProgressRowItem = ({ course, grade }) => {
  const navigation = useNavigate();
  const moodlev2 = useMoodleV2()
  const [certificadoAvailable, setCertificadoAvailable] = React.useState(null);
  const [data /* setData */] = React.useState({
    name: course.displayname,
    start: new Date(course.startdate * 1000).toLocaleDateString(),
    end:
      course.enddate !== 0
        ? new Date(course.enddate * 1000).toLocaleDateString()
        : "Sin fecha de termino",
  });

  useEffect(() => {
    const fetchCerts = async () => {
      let courseContent = await moodlev2.getCourseContentByID(course.id);
      // recorrer el course content y buscar el modulo que tenga certificado
      /* console.log(courseContent) */
      courseContent.modules && courseContent.modules.forEach((module) => {
        if(module.modules && module.modules.length > 0){
          module.modules.forEach((submodule) => {
            // si el modulo tiene el prefijo cert y es visible para el usuario
            if(submodule.name.toLowerCase().startsWith("cert") && submodule.uservisible){
              console.log(submodule)
              setCertificadoAvailable(`${submodule.url}&downloadown=1`);
            }
          })
        }
      })
    }
    fetchCerts()
  }, [course.id]);

  const handleDetailNavigation = () =>
    navigation(`/listcourses/progress/${course.id}`, {
      state: { name: data.name },
    });

    const handleCourseNavigation =  async () => {
      if(certificadoAvailable){
        window.location.href = certificadoAvailable;
      }
      /* navigation(`/detailcourse/${course.id}`, {
        state: { name: data.name },
      }); */
      // recorrer el course content y buscar el modulo que tenga certificado
    }

  return (
    <>
      <tr>
        <th onClick={handleDetailNavigation} scope="row" className="title-row">
          {data.name}
        </th>
        <td>{grade}</td>
        <td>{data.start}</td>
        <td>{data.end}</td>
        <td style={{cursor:'pointer', fontWeight:certificadoAvailable ? 700 : 500}} onClick={handleCourseNavigation}>
          {certificadoAvailable && <DownloadIcon />}
          {certificadoAvailable ? "Descargar" : '-'}
          </td>
        {/* <td>Ver respuestas</td> */}
      </tr>
    </>
  );
};
